<template>
  <div class="app-conf-page">
    <el-input placeholder="请输入URL" style="width: 100%" type="text" v-model.trim="url"></el-input>
    <el-button style="margin-top: 10px" type="primary" :disabled="!url" @click="updateVersion">提交</el-button>
  </div>
</template>

<script>
import { updateTemplateVersion } from '@/domains/base-xgb/xgb-app-conf'

export default {
  data() {
    return {
      url: ''
    }
  },
  methods: {
    updateVersion() {
      if (!this.url) return
      updateTemplateVersion({
        downloadurl: this.url.replace(/\s+/g, '')
      }).then(() => {
        this.$message.success('提交成功！')
      })
    }
  }
}
</script>