<template>
  <div class="app-conf-page">
    <el-form v-model="form" label-width="200px">
      <el-form-item label="App检测更新版本号">
        <el-input type="text" v-model.trim="form.Number" />
      </el-form-item>
      <el-form-item label="下载版本号">
        <el-input type="text" v-model.trim="form.DownloadNumber" />
      </el-form-item>
      <el-form-item label="安卓版本信息">
        <el-input type="textarea" :rows="8" v-model.trim="form.Info" />
      </el-form-item>
      <el-form-item label="强制更新渠道">
        <el-select
          style="width: 100%"
          v-model="forceUpdateChannel"
          filterable
          default-first-option
          placeholder="请选择渠道"
        >
          <el-option key="all" label="all" value="all"></el-option>
          <el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="强制更新版本">
        <el-select
          style="width: 100%"
          v-model="forceUpdateVersion"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="请选择或填写版本"
        >
          <el-option label="all" value="all"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-tag
          class="drag-tag"
          v-for="(tag, idx) in forceUpdateLimits"
          :key="idx"
          closable
          @close="deleteUpdateLimit(idx)"
        >{{`${tag.channel} - ${tag.version}`}}</el-tag>
      </el-form-item>
      <el-form-item>
        <el-button
          :disabled="!forceUpdateVersion.length"
          type="primary"
          @click="addUpdateLimit"
        >添加更新条件</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getAndroid, updateAndroid } from '@/domains/base-xgb/xgb-app-conf'
import { getKVConfigContent } from '@/domains/base-baoer'

export default {
  data() {
    return {
      form: {
        Number: '',
        DownloadNumber: '',
        Info: ''
      },
      forceUpdateChannel: '',
      forceUpdateVersion: [],
      forceUpdateLimits: [],
      options: []
    }
  },
  mounted() {
    getKVConfigContent('updated').then((res) => {
      if (res.channel) {
        this.options = res.channel.split(',')
      }
    })
    getAndroid().then((data) => {
      if (data && data.ForceUpdateVersions) {
        this.forceUpdateLimits = data.ForceUpdateVersions.split(',').map((item) => {
          const channel = item.split(':')[0]
          const version = item.split(':')[1]
          const value = `${channel}:${version}`
          return {
            channel,
            version,
            value
          }
        })
      }
      // data is:
      // DownloadNumber: 111
      // DownloadUrl: "https://baodownload.xuangubao.cn/bao.wallstreetcn_v111.apk"
      // ForceUpdateVersions: ""
      // Info: "对于各位用户大大们最近还有打不开文章页的问题↵反反复复修，这次真的是修好了！！↵那个程序猿已经被产品经理又揍了一顿.....↵新年这段时间行情这么好，希望大家能原谅他....."
      // IsForced: false
      // Number: 122
      if (data) this.form = data
    })
  },
  methods: {
    addUpdateLimit() {
      if (!this.forceUpdateVersion || !this.forceUpdateVersion.length) return
      if (this.forceUpdateLimits.find((x) => x.value === 'all:all')) return
      this.forceUpdateVersion.forEach((item) => {
        const value = `${this.forceUpdateChannel}:${item}`
        const tempCheck = (x) => {
          return x.value === value || (x.channel === this.forceUpdateChannel && x.version === 'all')
        }
        if (this.forceUpdateLimits.find(tempCheck)) return
        if (value === 'all:all') {
          this.forceUpdateLimits = [
            {
              channel: this.forceUpdateChannel,
              version: item,
              value
            }
          ]
          return
        }
        if (item === 'all') {
          this.forceUpdateLimits = [
            ...this.forceUpdateLimits.filter((item) => item.channel !== this.forceUpdateChannel),
            {
              channel: this.forceUpdateChannel,
              version: item,
              value
            }
          ]
          return
        }
        this.forceUpdateLimits.push({
          channel: this.forceUpdateChannel,
          version: item,
          value
        })
      })
      this.forceUpdateChannel = ''
      this.forceUpdateVersion = []
    },
    deleteUpdateLimit(idx) {
      this.forceUpdateLimits.splice(idx, 1)
    },
    submit() {
      const data = { ...this.form }
      if (!this.forceUpdateLimits || !this.forceUpdateLimits.length) {
        data.ForceUpdateVersions = ''
      } else {
        data.ForceUpdateVersions = this.forceUpdateLimits.map((item) => item.value).join(',')
      }
      data.Number = parseInt(data.Number, 10)
      data.DownloadNumber = parseInt(data.DownloadNumber, 10)
      updateAndroid({
        ...data,
        IsForced: false
      }).then(() => {
        this.$message.success('编辑成功！')
      })
    }
  }
}
</script>
