<template>
  <div class="app-conf-page">
    <el-input type="text" v-model.trim="code"></el-input>
    <div class="mark mt-2">深色是1，浅色是0。从左往右从上往下数</div>
    <el-button style="margin-top: 10px" type="primary" @click="check">查询</el-button>
    <template v-if="user">
      <el-card style="margin-top: 10px" class="box-card">
        <div v-for="(v, k) in user" :key="k" class="item">
          <template v-if="k !== 'Portrait'">
            <span>{{ k }}：</span>
            <span style="font-weight: 600">{{ v }}</span>
          </template>
          <template v-else>
            <span>{{ k }}：</span>
            <img :src="v" alt="xgb" />
          </template>
        </div>
      </el-card>
    </template>
  </div>
</template>

<script>
import { getAntiFake } from '@/domains/base-xgb/xgb-app-conf'

export default {
  data() {
    return {
      code: '10110101000010111101011111111111',
      user: null
    }
  },
  methods: {
    check() {
      if (!this.code) return
      getAntiFake({
        code: this.code
      }).then((res) => {
        if (res) this.user = res
      })
    }
  }
}
</script>
<style scoped>
.item {
  font-size: 14px;
  padding: 10px 0;
}
.box-card {
  width: 480px;
}
img {
  height: auto;
  width: 150px;
}
</style>
