<template>
  <div class="app-conf-page">
    <el-form v-model="form" label-width="200px">
      <el-form-item label="App检测更新版本号">
        <el-input v-model="form.Number" />
      </el-form-item>
      <el-form-item label="iOS版本信息">
        <el-input type="textarea" :rows="8" v-model="form.Info" />
      </el-form-item>
      <el-form-item label="强制更新版本">
        <el-select
          v-model="updateVersions"
          multiple
          filterable
          allow-create
          default-first-option
          placeholder="请选择或填写版本"
        >
          <el-option label="all" value="all"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getiOS, updateiOS } from '@/domains/base-xgb/xgb-app-conf'

export default {
  data() {
    return {
      form: {
        Number: '',
        Info: ''
      },
      updateVersions: []
    }
  },
  mounted() {
    getiOS().then((res) => {
      this.form = res
      this.updateVersions = res.ForceUpdateVersions ? res.ForceUpdateVersions.split(',') : []
    })
  },
  methods: {
    submit() {
      const data = { ...this.form }
      if (!this.updateVersions || !this.updateVersions.length) {
        data.ForceUpdateVersions = ''
      } else {
        if (this.updateVersions.includes('all')) {
          data.ForceUpdateVersions = 'all'
        } else {
          data.ForceUpdateVersions = this.updateVersions.join(',')
        }
        this.updateVersions = data.ForceUpdateVersions.split(',')
      }
      updateiOS({
        ...data,
        IsForced: false
      }).then(() => {
        this.$message.success('编辑成功！')
      })
    }
  }
}
</script>
