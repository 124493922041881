<template>
  <div class="app-conf-page">
    <el-select v-model="value" multiple filterable allow-create style="width: 100%">
      <el-option v-for="item in options" :key="item" :label="item"></el-option>
    </el-select>
    <el-button style="margin-top: 10px" type="primary" @click="submit">提交</el-button>
  </div>
</template>

<script>
import { getIOSReview, updateIOSReview } from '@/domains/base-xgb/xgb-app-conf'

export default {
  data() {
    return {
      value: [],
      options: []
    }
  },
  methods: {
    submit() {
      updateIOSReview({
        Versions: this.value
      }).then(() => {
        this.$message.success('提交成功！')
      })
    }
  },
  mounted() {
    getIOSReview().then((res) => {
      this.value = res.data
    })
  }
}
</script>
