<template>
  <div class="app-conf-page">
    <el-switch :modelValue="isHide" active-text="无交易" inactive-text="有交易" @change="handleChange"></el-switch>
  </div>
</template>

<script>
import { getIOSHideTrading, updateIOSHideTrading } from '@/domains/base-baoer'

export default {
  data() {
    return {
      isHide: ''
    }
  },
  created() {
    this.getIOSHideTrading()
  },
  methods: {
    getIOSHideTrading() {
      getIOSHideTrading().then((res) => {
        this.isHide = res
      })
    },
    handleChange(value) {
      updateIOSHideTrading({ hidden: value }).then((res) => {
        this.isHide = value
      })
    }
  }
}
</script>