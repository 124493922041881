<template>
  <div class="app-conf-page">
    <el-select
      style="width: 100%"
      v-model="channel"
      multiple
      filterable
      allow-create
      placeholder="请输入渠道名称"
    >
      <el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
    </el-select>
    <el-button style="margin-top: 10px" type="primary" @click="submit">提交</el-button>
  </div>
</template>

<script>
import { getAndroidHideTrading, updateAndroidHideTrading } from '@/domains/base-xgb/xgb-app-conf'

export default {
  data() {
    return {
      channel: null,
      allChannel: [],
      options: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    submit() {
      updateAndroidHideTrading({
        Channels: this.channel
      }).then(() => {
        this.$message.success('提交成功！')
      })
    },
    getData() {
      getAndroidHideTrading().then((res) => {
        if (res && res.Channels) {
          this.options = res.Channels
          this.channel = res.Channels
        }
      })
    }
  }
}
</script>