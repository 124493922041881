<template>
  <div class="app-conf-page">
    <el-table size="medium" :data="refreshList">
      <el-table-column label="内容">
        <template #default="{row}">
          <editable-label :modelValue="row.Content" @onChange="val => change(row, val)" />
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="{$index}">
          <el-button size="mini" type="danger" @click="remove($index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <br />
    <el-button icon="el-icon-plus" @click="addOne">添加</el-button>
    <el-button icon="el-icon-s-promotion" @click="submit" type="primary">提交</el-button>
  </div>
</template>

<script>
import { getRefreshText, updateRefreshText } from '@/domains/base-xgb/xgb-app-conf'
import EditableLabel from '@/views/settings/components/editable-label'

export default {
  components: {
    EditableLabel
  },
  data() {
    return {
      refreshList: []
    }
  },
  mounted() {
    getRefreshText().then((res) => {
      if (res.RefreshTexts && res.RefreshTexts.length) {
        this.refreshList = res.RefreshTexts
      }
    })
  },
  methods: {
    change(row, val) {
      row.Content = val
    },
    addOne() {
      this.refreshList.push({ Content: '' })
    },
    remove(idx) {
      this.refreshList.splice(idx, 1)
    },
    submit() {
      updateRefreshText({
        RefreshTexts: this.refreshList
      }).then(() => {
        this.$message.success('提交成功！')
      })
    }
  }
}
</script>
