import xgb from '@/http/xgb'
import baoer from '@/http/baoer'

export const fetchRecommend = params => xgb.get('/api/admin/settings', { params })
export const updateRecommend = data => xgb.post('/api/admin/settings', data)
export const getPzfk = params => xgb.get('api/pc/panzhongfengkou', { params })
export const updatePzfk = data => xgb.post('api/admin/settings/trending', data)
export const getAndroid = () => xgb.get('/api/admin/settings/android_version')
export const updateAndroid = data => xgb.post('/api/admin/settings/android_version', data)
export const getiOS = () => xgb.get('/api/admin/settings/ios_version')
export const updateiOS = data => xgb.post('/api/admin/settings/ios_version', data)
export const getSplashList = params => xgb.get('/api/admin/splash/list', { params })
export const updateSplash = (id, data) => xgb.put(`/api/admin/splashes/${id}`, data)
export const deleteSplash = id => xgb.delete(`/api/admin/splashes/${id}`)
export const addSplash = data => xgb.post('/api/admin/splash', data)
export const getEditorChoice = () => xgb.get('/api/admin/settings/editorChoices')
export const updateEditorChoice = data => xgb.post('/api/admin/settings/editorChoices', data)
export const getUserList = params => xgb.get('/api/admin/event/robot/users/list', { params })
export const getRefreshText = () => xgb.get('/api/admin/settings/refreshText')
export const updateRefreshText = data => xgb.post('/api/admin/settings/refreshText', data)
export const getAwardRecords = params => xgb.get('/api/admin/awardedPrize/list', { params })
export const giveAward = data => xgb.post('/api/admin/awardPrize', data)
export const giveAwardV2 = data => baoer.post('/api/v6/admin/reward/manual', data)
export const getAdList = params => xgb.get('/api/admin/commercials/list', { params })
export const getAd = id => xgb.get(`/api/admin/commercials/${id}`)
export const updateAd = (id, data) => xgb.put(`/api/admin/commercials/${id}`, data)
export const addAd = data => xgb.post('/api/admin/commercials', data)
export const deleteAd = id => xgb.delete(`/api/admin/commercials/${id}`)
export const getCouponList = params => xgb.get('/api/admin/couponEvt/list', { params })
export const getCoupon = id => xgb.get(`/api/admin/couponEvt/${id}`)
export const updateCoupon = (id, data) => xgb.put(`/api/admin/couponEvt/${id}`, data)
export const addCoupon = data => xgb.post('/api/admin/couponEvt', data)
export const getTradingBanner = () => xgb.get('/api/admin/settings/trading')
export const updateTradingBanner = data => xgb.post('/api/admin/settings/trading', data)
export const getIOSReview = () => xgb.get('/api/admin/misc/paymentHiddenVersions')
export const updateIOSReview = data => xgb.post('/api/admin/misc/paymentHiddenVersions', data)
export const getAlliance = () => xgb.get('/api/admin/alliance/status')
export const getPremiumTabs = () => xgb.get('/api/admin/settings/premiumTab')
export const updatePremiumTabs = data => xgb.post('/api/admin/settings/premiumTab', data)
export const getPromoteLinkList = params => xgb.get('/api/admin/promoteLink/list', { params })
export const getPromoteLink = id => xgb.get(`/api/admin/promoteLink/${id}`)
export const updatePromoteLink = (id, data) => xgb.put(`/api/admin/promoteLink/${id}`, data)
export const addPromoteLink = data => xgb.post('/api/admin/promoteLink', data)
export const getPCOriginal = () => xgb.get('/api/admin/settings/original')
export const updatePCOriginal = data => xgb.post('/api/admin/settings/original', data)
export const getFirstCharge = () => xgb.get('/api/admin/settings/firstChargeEvent')
export const updateFirstCharge = data => xgb.post('/api/admin/settings/firstChargeEvent', data)
export const getAuthorList = params => xgb.get('/api/admin/authors', { params })
export const getAuthor = id => xgb.get(`/api/admin/authors/${id}`)
export const updateAuthor = (id, data) => xgb.put(`/api/admin/authors/${id}`, data)
export const addAuthor = data => xgb.post('/api/admin/authors', data)
export const getChannel = params => xgb.get('/api/admin/channels/list', { params })
export const getFastSubject = () => xgb.get('/api/admin/settings/fastSubject')
export const updateFastSubject = data => xgb.post('/api/admin/settings/fastSubject', data)
export const getAndroidHideTrading = () => xgb.get('/api/admin/settings/androidHideTradingChans')
export const updateAndroidHideTrading = data => xgb.post('/api/admin/settings/androidHideTradingChans', data)
export const updateTemplateVersion = params => {
  return xgb.post('/api/admin/settings/article_template_v2', { params })
}
export const getAntiFake = params => xgb.get('/api/admin/users/antiFake', { params })

export const getIosLap = params => baoer.get('xgbadmin/v2/conf/iosIap', { params })
export const updateIosLap = data => baoer.put('xgbadmin/v2/conf/iosIap', data)