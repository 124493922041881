<template>
  <el-tabs v-model="currentTab">
    <el-tab-pane label="安卓版本信息" name="first">
      <AndroidVersion />
    </el-tab-pane>
    <el-tab-pane label="安卓隐藏交易" lazy name="second">
      <AndroidHideTrading />
    </el-tab-pane>
    <el-tab-pane label="iOS隐藏证券交易" lazy name="third">
      <IOSHideTrading />
    </el-tab-pane>
    <el-tab-pane label="iOS版本信息" lazy name="fourth">
      <IOSVersion />
    </el-tab-pane>
    <el-tab-pane label="iOS审核版本" lazy name="fifth">
      <IOSReview />
    </el-tab-pane>
    <el-tab-pane label="iOS内购" lazy name="sixth">
      <IOSLap />
    </el-tab-pane>
    <el-tab-pane label="文章模板" lazy name="seventh">
      <TemplateVersion />
    </el-tab-pane>
    <el-tab-pane label="刷新文本" lazy name="refresh">
      <RefreshText />
    </el-tab-pane>
    <el-tab-pane label="防伪标记查询" lazy name="eighth">
      <AntiFake />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import AndroidVersion from '@/views/settings/app-conf/android-version.vue'
import AndroidHideTrading from '@/views/settings/app-conf/android-hide-trading'
import IOSHideTrading from '@/views/settings/app-conf/ios-hide-trading'
import IOSReview from '@/views/settings/app-conf/ios-review'
import IOSVersion from '@/views/settings/app-conf/ios-version'
import IOSLap from '@/views/settings/app-conf/ios-lap'
import TemplateVersion from '@/views/settings/app-conf/template-version'
import AntiFake from '@/views/settings/app-conf/anti-fake'
import RefreshText from '@/views/settings/app-conf/refresh-text'

export default {
  name: 'AppConfTab',
  data() {
    return {
      currentTab: 'first'
    }
  },
  components: {
    AndroidVersion,
    AndroidHideTrading,
    IOSHideTrading,
    IOSReview,
    IOSVersion,
    IOSLap,
    TemplateVersion,
    AntiFake,
    RefreshText
  }
}
</script>
<style lang="scss">
.app-conf-page {
  margin: 0 auto;
  max-width: 920px;
  padding-top: 20px;
  &.ios-lap {
    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
