<template>
  <div class="app-conf-page ios-lap">
    <ul>
      <li v-for="(item, idx) in items" :key="idx">
        <el-form label-width="200px">
          <el-form-item label="产品">
            <el-input v-model="item.product_id" />
          </el-form-item>
          <el-form-item label="宝币">
            <el-input v-model="item.baobi_amount" />
          </el-form-item>
          <el-form-item label="人民币">
            <el-input v-model="item.rmb_price" />
          </el-form-item>
        </el-form>
      </li>
    </ul>
    <div style="text-align: right">
      <el-button type="primary" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>
import { getIosLap, updateIosLap } from '@/domains/base-xgb/xgb-app-conf'

export default {
  data() {
    return {
      items: []
    }
  },
  mounted() {
    getIosLap().then((res) => {
      if (res && res.length) this.items = res
    })
  },
  methods: {
    submit() {
      const items = this.items.map((i) => ({
        ...i,
        baobi_amount: +i.baobi_amount,
        rmb_price: +i.rmb_price
      }))
      updateIosLap(items).then(() => {
        this.$message.success('编辑成功！')
      })
    }
  }
}
</script>