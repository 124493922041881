<template>
  <div class="editable-label">
    <template v-if="!editable">
      <el-button size="mini" type="primary" @click="toggle">编辑</el-button>
      <span v-if="val">{{ val }}</span>
    </template>
    <template v-else>
      <el-input
        style="margin-bottom: 4px"
        v-model="val"
        autofocus
        :rows="6"
        :type="type"
        ref="input"
      ></el-input>
      <el-button size="mini" type="success" @click="onSubmit">保存</el-button>
      <el-button size="mini" type="info" @click="onCancel">取消</el-button>
    </template>
  </div>
</template>

<script>
// @keyup.enter.native="onSubmit"
// @keyup.esc.native="onCancel"

export default {
  props: {
    modelValue: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data() {
    return {
      editable: false,
      val: this.modelValue
    }
  },
  watch: {
    modelValue(val) {
      this.val = val
    }
  },
  methods: {
    toggle() {
      this.editable = !this.editable
    },
    onSubmit() {
      this.editable = false
      this.$emit('on-change', this.val.replace('\n', ''))
    },
    onCancel() {
      this.val = this.modelValue
      this.editable = false
    }
  }
}
</script>
<style lang="scss" scoped>
.editable-label {
  .el-button {
    margin-right: 10px;
  }
  i[class^='el-icon'] {
    font-size: 16px;
  }
}
</style>
